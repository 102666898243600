<script setup lang="ts">
import { TheNuxtLink } from "#components";
import type { PinnedAsset, PinnedTypes } from "~/types/forms/assets";

const { getAssetUrl } = useMediaLink();
const { locale } = useNuxtApp().$i18n.global;
const baseURl = useBaseUrl();

const props = defineProps<{
  desktop: string;
  tablet: string;
  mobile: string;
  bg?: string;
  pinLocation?: {
    md: "left" | "right" | "ignore";
    xl2: "left" | "right" | "ignore";
  };
  contentLink?: {
    desktop?: string;
    tablet?: string;
    mobile?: string;
  };
  pins: PinnedAsset;
}>();

const isExternalUrl = (url: string) => {
  // relative url, cant be external
  if (url.startsWith("/")) return false;
  return (
    url.indexOf(baseURl ?? "/") !== 0 &&
    (!window || url.indexOf(window.location.origin) !== 0)
  );
};

const mappedPins = computed(() => {
  return Object.fromEntries(
    Object.entries(props.pins).map(([key, asset]) => [
      key,
      asset
        ? {
            src: getAssetUrl(asset),
            url: extractFromLocale(locale.value, asset.attributes.linkUrl),
          }
        : null,
    ]),
  ) as { [key in PinnedTypes]: { src: string; url: string } | null };
});
</script>

<template>
  <div class="relative h-full">
    <!--          Background banner image-->
    <div
      v-if="bg"
      class="absolute inset-0 bg-center bg-no-repeat bg-cover"
      :style="{
        backgroundImage: `url('${bg ?? '/assets/nami/banner/nc-world.png'}')`,
      }"
    ></div>

    <!--          Main banner image-->
    <div
      class="hidden 2xl:block h-full object-cover bg-center bg-no-repeat bg-[auto_100%] relative z-[1]"
      :style="{ backgroundImage: `url('${desktop}')` }"
    ></div>
    <div
      class="hidden md:block 2xl:hidden h-full object-cover bg-center bg-no-repeat bg-[auto_100%] relative z-[1]"
      :style="{ backgroundImage: `url('${tablet}')` }"
    ></div>
    <div
      class="md:hidden h-full object-cover bg-center bg-no-repeat bg-[auto_100%] relative z-[1]"
      :style="{ backgroundImage: `url('${mobile}')` }"
    ></div>

    <!--          Content overlay (for interactivity)-->
    <div class="absolute left-1/2 -translate-x-1/2 top-0 h-full z-[1]">
      <div
        v-if="contentLink?.desktop"
        class="hidden 2xl:block aspect-[3] px-20 py-12 h-full"
      >
        <div
          class="grid gap-4 h-full"
          :class="{
            'grid-cols-[calc(100%/344*75)_1fr]': pinLocation?.xl2 === 'left',
            'grid-cols-[1fr_calc(100%/344*75)]': pinLocation?.xl2 === 'right',
          }"
        >
          <TheNuxtLink
            :href="contentLink.desktop"
            :target="isExternalUrl(contentLink?.desktop) ? '_blank' : undefined"
            :class="{ 'order-2': pinLocation?.xl2 === 'left' }"
          >
            <!--            CONTENT-->
          </TheNuxtLink>
          <div
            v-if="pinLocation?.xl2 !== 'ignore'"
            :class="{ 'order-1': pinLocation?.xl2 === 'left' }"
            class="grid grid-rows-2 min-h-0 gap-4"
          >
            <!--            NAMI PINS-->
            <NuxtLink
              v-if="mappedPins['web.top']"
              :href="mappedPins['web.top'].url"
            >
              <AsyncImage
                class="min-h-0 w-full h-full object-cover"
                :src="mappedPins['web.top'].src"
              />
            </NuxtLink>
            <NuxtLink
              v-if="mappedPins['web.bottom']"
              :href="mappedPins['web.bottom'].url"
            >
              <AsyncImage
                class="min-h-0 w-full h-full object-cover"
                :src="mappedPins['web.bottom'].src"
              />
            </NuxtLink>
          </div>
        </div>
      </div>
      <div
        v-if="contentLink?.tablet"
        class="hidden md:block 2xl:hidden aspect-[64/25] px-12 py-9 lg:px-16 lg:py-12 h-full"
      >
        <div
          class="grid gap-3 lg:gap-4 h-full"
          :class="{
            'grid-cols-[calc(100%/112*25)_1fr]': pinLocation?.md === 'left',
            'grid-cols-[1fr_calc(100%/112*25)]': pinLocation?.md === 'right',
          }"
        >
          <TheNuxtLink
            :href="contentLink.tablet"
            :target="isExternalUrl(contentLink.tablet) ? '_blank' : undefined"
            :class="{ 'order-2': pinLocation?.md === 'left' }"
          >
            <!--            CONTENT-->
          </TheNuxtLink>
          <div
            v-if="pinLocation?.md !== 'ignore'"
            :class="{ 'order-1': pinLocation?.md === 'left' }"
            class="grid grid-rows-2 min-h-0 gap-3 lg:gap-4"
          >
            <!--            NAMI PINS-->
            <NuxtLink
              v-if="mappedPins['tablet.top']"
              :href="mappedPins['tablet.top'].url"
            >
              <AsyncImage
                class="min-h-0 w-full h-full object-cover"
                :src="mappedPins['tablet.top'].src"
              />
            </NuxtLink>
            <NuxtLink
              v-if="mappedPins['tablet.bottom']"
              :href="mappedPins['tablet.bottom'].url"
            >
              <AsyncImage
                class="min-h-0 w-full h-full object-cover"
                :src="mappedPins['tablet.bottom'].src"
              />
            </NuxtLink>
          </div>
        </div>
      </div>
      <div
        v-if="contentLink?.mobile"
        class="md:hidden aspect-[39/24] px-8 py-6 h-full grid"
      >
        <TheNuxtLink
          :href="contentLink.mobile"
          :target="isExternalUrl(contentLink.mobile) ? '_blank' : undefined"
        >
          <!--          CONTENT-->
        </TheNuxtLink>
      </div>
    </div>
  </div>
</template>

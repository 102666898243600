<template>
  <div
    class="relative bg-center bg-no-repeat bg-cover w-full [@media(max-width:390px)]:aspect-[39/24] max-h-60 md:max-h-[300px] md:h-[300px] lg:h-[400px] lg:max-h-[400px] 2xl:h-128 2xl:max-h-128"
    :style="{
      backgroundImage: data?.background
        ? `url('${data.background}')`
        : undefined,
    }"
  >
    <div
      class="prev-el select-none lg:w-16 xl:w-24 hidden canhover:sm:flex items-center justify-center absolute inset-y-0 z-[5] left-0 opacity-0 hover:opacity-100 bg-black/40 transition-opacity text-white cursor-pointer"
    >
      <IconChevronLeft :size="48" />
    </div>
    <Skeleton v-if="pending" class="h-full w-full !rounded-none !shadow-none" />
    <swiper
      v-else-if="data"
      :slidesPerView="1"
      :centeredSlides="true"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: true,
      }"
      :speed="1000"
      :loop="true"
      :mousewheel="false"
      effect="fade"
      :fade-effect="{ crossFade: true }"
      :navigation="{
        nextEl: '.next-el',
        prevEl: '.prev-el',
      }"
      :modules="modules"
      class="h-auto w-full aspect-[39/24] max-h-60 md:max-h-[300px] lg:max-h-[400px] 2xl:max-h-128"
    >
      <swiper-slide
        v-for="banner in data.slides"
        class="h-full max-h-60 md:max-h-[300px] lg:max-h-[400px] 2xl:max-h-128"
      >
        <HomepageATFBanner v-bind="banner" :pins="data.pins" />
      </swiper-slide>
      <swiper-slide
        v-if="false"
        class="h-full max-h-60 md:max-h-[300px] lg:max-h-[400px] 2xl:max-h-128"
      >
        <TheNuxtLink :to="`/${mappedLocale}/creators`">
          <div class="relative h-60 sm:h-full">
            <AsyncImage
              class="w-full h-full object-cover backdrop-blur-md rounded-lg sm:rounded-none"
              style="object-position: center 20%"
              src="/assets/nami/namifireworks.jpg"
              alt="contest-header"
            />
            <div class="fade-bg"></div>
            <div class="absolute inset-0 home-carousel py-2 lg:py-8 2xl:py-12">
              <div
                class="container max-h-full h-full grid grid-rows-[min-content_auto] text-white gap-y-2 md:gap-y-4 xl:gap-y-4 2xl:gap-y-8"
              >
                <div
                  class="font-bold text-lg md:text-xl lg:text-3xl 2xl:text-5xl w-full md:w-3/4"
                >
                  Creativity unleashed!
                </div>
                <div
                  class="relative min-h-0 h-full w-3/4 md:w-1/2 self-stretch"
                >
                  <div
                    class="absolute inset-0 space-y-1 md:space-y-2 lg:space-y-4 text-sm md:text-base 2xl:text-xl preview-description"
                  >
                    <p>Build your fandom on NamiComi.</p>

                    <p class="hidden sm:block">
                      From subscriptions to social posts, DMs to group chats,
                      you will have everything you need to interact directly
                      with your fans anytime, anywhere.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TheNuxtLink>
      </swiper-slide>
      <swiper-slide
        v-if="!data.slides.length"
        class="h-full max-h-60 md:max-h-[300px] lg:max-h-[400px] 2xl:max-h-128"
      >
        <TheNuxtLink :to="`/${mappedLocale}/publish/overview`">
          <div class="relative h-60 sm:h-full">
            <AsyncImage
              class="w-full h-full object-cover backdrop-blur-md rounded-lg sm:rounded-none"
              style="object-position: center 20%"
              src="/assets/publish/publishbanner.png"
              alt="contest-header"
            />
            <div class="fade-bg-right"></div>
            <div class="absolute inset-0 home-carousel py-2 lg:py-8 2xl:py-12">
              <div
                class="container max-h-full h-full flex flex-col items-end text-white gap-y-2 md:gap-y-4 xl:gap-y-4 2xl:gap-y-8"
              >
                <div
                  class="font-bold text-lg md:text-xl lg:text-3xl 2xl:text-5xl"
                >
                  Your content, your rules
                </div>
                <div class="relative min-h-0 h-full w-1/2 lg:w-1/3">
                  <div
                    class="absolute inset-0 space-y-1 md:space-y-2 lg:space-y-4 text-sm md:text-base 2xl:text-xl preview-description text-right"
                  >
                    <p>
                      Publish your titles on the only comics ecosystem that has
                      creators at its core.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TheNuxtLink>
      </swiper-slide>
      <swiper-slide
        v-if="!data.slides.length"
        class="h-full max-h-60 md:max-h-[300px] lg:max-h-[400px] 2xl:max-h-128"
      >
        <TheNuxtLink :to="`/${mappedLocale}/contest/overview`">
          <div class="relative h-60 sm:h-full">
            <AsyncImage
              class="w-full h-full object-cover backdrop-blur-md rounded-lg sm:rounded-none"
              style="object-position: center 20%"
              src="/assets/contest/illustration.jpg"
              alt="contest-header"
            />
            <div class="fade-bg"></div>
            <div class="absolute inset-0 home-carousel py-2 lg:py-8 2xl:py-12">
              <div
                class="container max-h-full h-full grid grid-rows-[min-content_auto] text-white gap-y-2 md:gap-y-4 xl:gap-y-4 2xl:gap-y-8"
              >
                <div
                  class="font-bold text-lg md:text-xl lg:text-3xl 2xl:text-5xl w-full md:w-3/4"
                >
                  {{ $t("components.namiShowcase.ctaContest") }}
                </div>
                <div
                  class="relative min-h-0 h-full w-3/4 md:w-1/2 self-stretch"
                >
                  <div
                    class="absolute inset-0 space-y-1 md:space-y-2 lg:space-y-4 text-sm md:text-base 2xl:text-xl preview-description"
                  >
                    <p>
                      Nami's Treasure Trove is a monthly contest held on
                      NamiComi.
                    </p>

                    <p class="hidden sm:block">
                      The first three chapters of a title that are published
                      from the first day of the month to the last day of the
                      month are used for calculating the rank.
                    </p>

                    <p>
                      Titles ranked within the top 3 are eligible for prizes.
                    </p>

                    <p>Prizes are guaranteed every month!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TheNuxtLink>
      </swiper-slide>
    </swiper>
    <div
      class="next-el select-none lg:w-16 xl:w-24 hidden canhover:sm:flex items-center justify-center absolute inset-y-0 z-[5] right-0 opacity-0 hover:opacity-100 bg-black/40 transition-opacity text-white cursor-pointer"
    >
      <IconChevronRight :size="48" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

// import required modules
import { Autoplay, EffectFade, Mousewheel, Navigation } from "swiper/modules";
import {
  type AssetRelation,
  type AvailableLanguages,
  Content,
  type PopulateRelationship,
} from "~/src/api";
import {
  getATFBannerBackgrounds,
  getATFBannerGroups,
  getATFBannerPins,
  getContentBlockAssets,
} from "~/src/content/util";
import { useQuery } from "@tanstack/vue-query";

const modules = [Autoplay, Mousewheel, Navigation, EffectFade];

const baseURl = useBaseUrl();
const nuxtApp = useNuxtApp();
const locale = nuxtApp.$i18n.global.locale;
const mappedLocale = computed(() => reverseLocaleMap(locale.value));
const { getAssetUrl } = useMediaLink();

const {
  data,
  suspense,
  isPending: pending,
} = useQuery({
  queryKey: ["homepage", "atf-banner"],
  queryFn: async () => {
    const blocks = await Content.getContentBlocks({
      includes: ["asset"],
      contentTypes: ["asset_list"],
      spaces: ["atf_banner"],
      order: {
        updatedAt: "desc",
      },
      showActive: true,
      limit: 1,
    }).catch((error) => {
      console.warn("An error occurred while fetching the showcase data");
      console.error(error);
      throw error;
    });

    const block = blocks.data.at(0);

    if (block) {
      const assets = getATFBannerBackgrounds(block);
      const backgrounds = assets.map((asset) => getAssetUrl(asset));

      const pins = getATFBannerPins(block ? getContentBlockAssets(block) : []);

      const groups = getATFBannerGroups(block);
      const slides = groups.map(({ assets }) => {
        const desktop = assets.find((a) => a.attributes.deviceType === "web")!;
        const tablet = assets.find(
          (a) => a.attributes.deviceType === "tablet",
        )!;
        const mobile = assets.find(
          (a) => a.attributes.deviceType === "mobile",
        )!;
        const bg = assets.find((a) => a.attributes.deviceType === "none");

        return {
          desktop: getAssetUrl(desktop),
          tablet: getAssetUrl(tablet),
          mobile: getAssetUrl(mobile),
          pinLocation: {
            md: tablet.attributes.parameters?.pinLocation ?? "right",
            xl2: desktop.attributes.parameters?.pinLocation ?? "right",
          },
          bg: bg ? getAssetUrl(bg) : undefined,
          contentLink: {
            desktop: getContentLink(desktop),
            tablet: getContentLink(tablet),
            mobile: getContentLink(mobile),
          },
        };
      });

      return {
        slides,
        pins,
        background: backgrounds[Math.floor(backgrounds.length * Math.random())],
        backgrounds,
      };
    }

    return {
      slides: [],
      pins: {
        "web.top": null,
        "web.bottom": null,
        "tablet.top": null,
        "tablet.bottom": null,
      },
      background: "",
    };
  },
  throwOnError: (error, query) => {
    throw ApiToNuxtError(error);
  },
  staleTime: useCacheTimings().homepage.atf_carousel,
});

onServerPrefetch(async () => {
  await suspense();
});

const localeParser = /^\/[a-z]{2,3}(-(?:[0-9a-z]{2,4}|roman))?/;
function adaptLinkLocaleIfOurs(link: string) {
  const isExternal =
    link.indexOf(baseURl ?? "/") !== 0 &&
    (!window || link.indexOf(window.location.origin) !== 0);
  if (isExternal) return link;

  const url = new URL(link);
  return `/${url.pathname.replace(localeParser, mappedLocale.value)}`;
}

function getContentLink(asset: PopulateRelationship<AssetRelation>) {
  const link =
    (asset.attributes.linkUrl[locale.value] ??
    Object.keys(asset.attributes.linkUrl).length)
      ? asset.attributes.linkUrl[
          Object.keys(asset.attributes.linkUrl)[0] as AvailableLanguages
        ]
      : "";

  if (!link) return "";
  return adaptLinkLocaleIfOurs(link);
}
</script>

<style scoped lang="postcss">
.fade-bg {
  @apply absolute left-0 inset-y-0 w-full lg:w-3/4;
  background: linear-gradient(to right, #000000cf 0%, #0000004f 100%);

  @media screen(sm) {
    background: linear-gradient(to right, #000000cf 0%, #00000000 100%);
  }
}

.fade-bg-right {
  @apply absolute right-0 inset-y-0 w-full lg:w-3/4;
  background: linear-gradient(to left, #000000cf 0%, #0000004f 100%);

  @media screen(sm) {
    background: linear-gradient(to left, #000000cf 0%, #00000000 100%);
  }
}

.preview-description {
  @apply select-none overflow-y-auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar-thumb {
    display: none;
    @apply rounded-full;
  }

  &:hover {
    -ms-overflow-style: initial;
    scrollbar-width: initial;

    &::-webkit-scrollbar-thumb {
      display: initial;
    }
  }
}
</style>

<template>
  <div class="space-y-4 lg:space-y-6">
    <div class="flex justify-between items-center home-carousel">
      <h2 class="text-xl sm:text-2xl lg:text-3xl font-bold flex-grow">
        <TheNuxtLink
          :href="`${mappedLocale}/title/search?sort=followCount.desc`"
        >
          {{ $t("components.namiMostLikedTitles.heading") }}
        </TheNuxtLink>
      </h2>
      <NamiButton
        button-type="secondary"
        text
        pill
        :icon="IconArrowRight"
        :href="`${mappedLocale}/title/search?sort=reactions.desc`"
      />
    </div>
    <HomepageCarouselSkeleton v-if="pending" />
    <div v-else-if="data" class="home-carousel overflow-hidden">
      <TitlesCarousel
        :titles="data.data"
        :breakpoints="{
          0: { slidesPerView: 3, spaceBetween: 8, autoHeight: true },
          360: { slidesPerView: 3, spaceBetween: 8, autoHeight: true },
          640: { slidesPerView: 3, spaceBetween: 8, autoHeight: true },
          768: { slidesPerView: 3, spaceBetween: 8, autoHeight: true },
          1024: { slidesPerView: 4, spaceBetween: 16, autoHeight: true },
          1280: { slidesPerView: 5, spaceBetween: 16, autoHeight: true },
          1536: { slidesPerView: 6, spaceBetween: 16, autoHeight: true },
          1920: { slidesPerView: 7, spaceBetween: 16, autoHeight: true },
          2560: { slidesPerView: 9, spaceBetween: 16, autoHeight: true },
        }"
        wrapper-class="home-carousel overflow home-carousel-ssr-style"
        navigation-id="most-liked-titles"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ALL_CONTENT, Title } from "~/src/api";
import { IconArrowRight } from "@tabler/icons-vue";
import { useQuery } from "@tanstack/vue-query";
import { useTitleFuzzyList } from "~/utils/tanstack/title";

const nuxtApp = useNuxtApp();
const mappedLocale = computed(() =>
  reverseLocaleMap(nuxtApp.$i18n.global.locale.value),
);

const {
  data,
  suspense,
  isPending: pending,
} = useQuery({
  ...useTitleFuzzyList({
    includes: ["cover_art", "organization", "tag"],
    order: { reactions: "desc" },
    limit: 18,
    contentRatings: ALL_CONTENT,
    forceCache: true,
  }),
  throwOnError: (error, query) => {
    throw ApiToNuxtError(error);
  },
  staleTime: useCacheTimings().homepage.liked_titles,
});

onServerPrefetch(async () => {
  await suspense();
});
</script>

<template>
  <div>
    <NamiShowcase />
  </div>
  <div class="max-w-[3860px] mx-auto" ref="pageBounding">
    <NamiStaffPicks class="mt-8 lg:my-12" />
    <NamiHotTitles class="mt-8 lg:my-12" />
    <NamiMostFollowedTitles class="mt-8 lg:my-12" />
    <div class="home-carousel space-y-4 lg:space-y-6 pt-4 lg:pt-6">
      <NamiLatestUpdatesNew :items="12" />
    </div>
    <NamiSoonToBeFavs class="my-8 lg:my-12" />
    <NamiMostLikedTitles class="my-8 lg:my-12" />
    <NamiNewTitles class="my-8 lg:my-12" />
    <div
      class="max-w-screen-2xl mx-auto home-carousel space-y-4 lg:space-y-6 pt-4 lg:pt-6"
    >
      <NewsShowcase />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useElementBounding } from "@vueuse/core";

const config = useRuntimeConfig();
const title = ref("NamiComi - Read Comics and Manga Online");
const description = ref(
  "NamiComi is the global ecosystem for creators and fans of comics and manga.",
);
const canonical = ref(`${config.public.baseUrl}/en`);

const pageBounding = ref<HTMLDivElement>();
const bounding = useElementBounding(pageBounding);
provide("currentPageBounding", bounding);

definePageMeta({
  title: "NamiComi - Read Comics and Manga Online",
});

useHead({
  title: title,
  link: [{ rel: "canonical", href: canonical }],
  meta: [
    {
      property: "og:title",
      content: `${title.value}`,
    },
    { name: "description", content: description },
  ],
});
</script>

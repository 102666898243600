<template>
  <div
    class="home-carousel grid responsive-gap grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 [@media(min-width:1920px)]:grid-cols-7 [@media(min-width:2560px)]:grid-cols-9"
  >
    <Skeleton class="w-full aspect-[5/7] mb-12" />
    <Skeleton class="w-full aspect-[5/7] mb-12" />
    <Skeleton class="w-full aspect-[5/7] mb-12" />
    <Skeleton class="hidden lg:block w-full aspect-[5/7] mb-12" />
    <Skeleton class="hidden xl:block w-full aspect-[5/7] mb-12" />
    <Skeleton class="hidden 2xl:block w-full aspect-[5/7] mb-12" />
    <Skeleton
      class="hidden [@media(min-width:1920px)]:block w-full aspect-[5/7] mb-12"
    />
    <Skeleton
      class="hidden [@media(min-width:2560px)]:block w-full aspect-[5/7] mb-12"
    />
    <Skeleton
      class="hidden [@media(min-width:2560px)]:block w-full aspect-[5/7] mb-12"
    />
  </div>
</template>
<script setup lang="ts"></script>

<template>
  <div class="space-y-6 xl:space-y-8">
    <div v-if="!noLink" class="flex items-center">
      <h1 class="text-xl sm:text-2xl lg:text-3xl font-bold flex-grow">
        <TheNuxtLink :href="`${locale}/news`">
          {{ $t("components.newsShowcase.title") }}
        </TheNuxtLink>
      </h1>
      <NamiButton
        button-type="secondary"
        text
        pill
        :icon="IconArrowRight"
        :href="`${locale}/news`"
      />
    </div>
    <div v-else class="flex items-center">
      <h1 class="text-xl sm:text-2xl lg:text-3xl font-bold flex-grow">
        NamiComi News
      </h1>
    </div>
    <div v-if="pending" class="grid sm:grid-cols-2 gap-6 xl:gap-8">
      <div class="w-full flex flex-col gap-4" v-for="n in 2">
        <Skeleton class="w-full aspect-[16/9]" />
        <Skeleton class="h-14" />
        <Skeleton class="h-[80px] sm:h-24" />
      </div>
    </div>
    <div v-else class="space-y-6 xl:space-y-8">
      <div v-if="news" class="grid sm:grid-cols-2 gap-6 xl:gap-8">
        <NewsCard
          v-for="item in [0, 1]"
          :id="news[item]?.id"
          :cover="news[item]?.cover!"
          :title="news[item]?.title.rendered"
          :excerpt="news[item]?.excerpt.rendered"
          :slug="news[item]?.slug"
        />
      </div>
      <div
        v-if="news"
        class="grid sm:grid-cols-2 xl:grid-cols-4 gap-6 xl:gap-8"
      >
        <NewsCard
          v-for="item in [2, 3, 4, 5]"
          :id="news[item]?.id"
          :cover="news[item]?.cover!"
          :title="news[item]?.title.rendered"
          :excerpt="news[item]?.excerpt.rendered"
          :slug="news[item]?.slug"
        />
      </div>
      <div
        v-if="news && moreNews"
        class="grid sm:grid-cols-2 xl:grid-cols-4 gap-6 xl:gap-8"
      >
        <NewsCard
          v-for="item in [6, 7, 8, 9, 10, 11, 12, 13]"
          :id="news[item]?.id"
          :cover="news[item]?.cover!"
          :title="news[item]?.title.rendered"
          :excerpt="news[item]?.excerpt.rendered"
          :slug="news[item]?.slug"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { IconArrowRight } from "@tabler/icons-vue";

const nuxtApp = useNuxtApp();
const locale = nuxtApp.$i18n.global.locale;

defineProps<{
  noLink?: boolean;
  moreNews?: boolean;
}>();

const {
  pending,
  data: news,
  error,
} = useAsyncData("news-showcase", async () => {
  const response = await axios<
    {
      id: number;
      _links: {
        "wp:featuredmedia": {
          href: string;
        }[];
      };
      title: {
        rendered: string;
      };
      excerpt: {
        rendered: string;
      };
      cover?: string;
      slug: string;
    }[]
  >("https://nami.news/wordpress/wp-json/wp/v2/posts?per_page=14", {
    responseType: "json",
  });

  const news = response.data;

  const covers = await Promise.all(
    news.map((newsItem) =>
      axios<{
        media_details: {
          sizes: {
            full: {
              source_url: string;
            };
          };
        };
      }>(newsItem._links["wp:featuredmedia"][0].href),
    ),
  );

  covers.forEach(
    (cover, index) =>
      (news[index].cover = cover.data.media_details.sizes.full.source_url),
  );

  return news;
});
</script>

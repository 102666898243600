<template>
  <div class="space-y-4 lg:space-y-6">
    <div class="flex justify-between items-center home-carousel">
      <h2 class="text-xl sm:text-2xl lg:text-3xl font-bold flex-grow">
        {{ $t("components.namiStaffPicks.heading") }}
      </h2>
    </div>
    <HomepageCarouselSkeleton v-if="pending || !titles || server" />
    <div v-if="shuffledTitles" class="home-carousel overflow-hidden">
      <TitlesCarousel
        :titles="shuffledTitles"
        source="wec"
        :breakpoints="{
          0: { slidesPerView: 3, spaceBetween: 8, autoHeight: true },
          360: { slidesPerView: 3, spaceBetween: 8, autoHeight: true },
          640: { slidesPerView: 3, spaceBetween: 8, autoHeight: true },
          768: { slidesPerView: 3, spaceBetween: 8, autoHeight: true },
          1024: { slidesPerView: 4, spaceBetween: 16, autoHeight: true },
          1280: { slidesPerView: 5, spaceBetween: 16, autoHeight: true },
          1536: { slidesPerView: 6, spaceBetween: 16, autoHeight: true },
          1920: { slidesPerView: 7, spaceBetween: 16, autoHeight: true },
          2560: { slidesPerView: 9, spaceBetween: 16, autoHeight: true },
        }"
        wrapper-class="home-carousel overflow home-carousel-ssr-style"
        navigation-id="staff-picks"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ALL_CONTENT, Content, isTitle, Title } from "~/src/api";
import { QueryClient, useQuery } from "@tanstack/vue-query";
import { ApiToNuxtError } from "~/utils/errors/404";
import { cacheTitle } from "~/utils/tanstack/title";

const {
  data: titles,
  suspense,
  isPending: pending,
} = useQuery({
  queryKey: ["homepage", "staff-picks"],
  queryFn: async ({ client }) => {
    const block = await Content.getContentBlocks({
      contentTypes: ["title_list"],
      spaces: ["editors_choice"],
      order: {
        updatedAt: "desc",
      },
      showActive: true,
      limit: 1,
    });

    const currentList = block.data.at(0);
    if (!currentList) throw new Error("No Weekly Editor's Choice list found.");

    const listTitles = currentList.relationships
      .filter(isTitle)
      .map((rel) => rel.id)
      .sort();

    const res = await Title.fuzzySearch({
      includes: ["cover_art", "organization", "tag"],
      ids: listTitles,
      contentRatings: ALL_CONTENT,
      forceCache: true,
    });
    res.data.forEach((title) => cacheTitle(() => client as QueryClient, title));
    return res;
  },
  select: (data) => data.data,
  throwOnError: (error, query) => {
    throw ApiToNuxtError(error);
  },
  staleTime: useCacheTimings().homepage.staff_picks,
});

const server = ref(true);
const shuffledTitles = ref(titles.value);

watchEffect(() => {
  if (server.value) return;
  shuffledTitles.value = titles.value?.shuffle();
});

onMounted(() => {
  server.value = false;
});

onServerPrefetch(async () => {
  await suspense();
  shuffledTitles.value = titles.value;
});
</script>
